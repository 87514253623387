/**
 * Fetches Messages and channel members of selected channel
 */
import React, { useEffect, useState, useRef } from "react";
import Helmet from "react-helmet";
import { Avatar, Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "../../style/messages";
import { fetchMessages, getChannelMembers, fetchChannelMetadata } from "../../services/pubnub";
import { useLocation } from "react-router";
import usePubNub from "../../utils/usePubNub";
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  capitalizeFirstLetter,
  capitalizeNameInitials,
  formatTimeToken,
  formatBannedMessage,
  getMessageFileUrl,
  membersFromLS,
  truncateChannelDescription,
  getMessageText,
} from "../../utils/helpers";
import Members from "./Members";
import MemberDetail from "./MemberDetail";
import Chat from "./Chat";
import { setLocalStorage } from "../../services/localStorage";
import MessagesCount from "./MessagesCount";
import ToggleChat from "./ToggleChat";

const Messages = (props) => {
  const firstUpdate = useRef(true);
  const classes = useStyles();
  const location = useLocation();
  const { pubnub } = usePubNub();
  const [messages, setMessages] = useState([]);
  const [members, setMembers] = useState(membersFromLS());
  const [channel, setChannel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [more, setMore] = useState({});
  const [loadMore, setLoadMore] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [memberDetails, setMemberDetails] = useState();
  const [toggleMemberDetails, setToggleMemberDetails] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState([]);
  const [toggledVal, setToggledVal] = useState("chat");
  const [channelID, setChannelID] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [endLoad, setEndLoad] = useState(false);
  const [page, setPage] = useState("");
  const matches = useMediaQuery('(min-width:400px)');


  // used to lazyload messages
  useEffect(() => {
    if (more.start !== 'end' && scrollPosition === 0) setLoadMore(true);
  }, [scrollPosition]);




  useEffect(() => {
    console.log('load', loadMore);
    fetchChannelMessages(more.start);
    setEndLoad(false);
  }, [loadMore]);


  useEffect(() => {
    setTimeout(() => {
      setEndLoad(true);
    }, 2500);
  }
    , [endLoad]);

  useEffect(() => {
    fetchChannelMembers();
    setLocalStorage("PubNubSelectedChannel", location.state.channel);
    console.log('what is the channel', props);
    setChannelID(location.state.channel);
    (async () => {
      try {
        const channelResponse = await fetchChannelMetadata(pubnub, location.state.channel);
        setChannel(channelResponse);
      } catch (e) { }
    })();


    //  gridRef.current.addEventListener('scroll',e => {
    //    console.log(e);
    //  });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toggledVal === "chat") {
      setChannelID(location.state.channel);
    } else if (toggledVal === "banned") {
      setChannelID("banned." + location.state.channel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledVal]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      if (channelID) fetchChannelMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  useEffect(() => {
    if (channelID && !loading) fetchChannelMessages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelID]);


  /**
   * Fetches channel members related to selected channel
   */
  const fetchChannelMembers = () => {
    setLoading(true);
    (async () => {
      try {
        const channelMembers = await getChannelMembers(pubnub, location.state.channel);
        setLocalStorage("PubNubChannelMembers", channelMembers.data);
        setMembers(channelMembers.data);
        setTotalCount(channelMembers.totalCount);
        setPage(channelMembers.next);
      } catch (e) { }
    })();
  };

  /**
   * Fetches channel messages related to selected channel
   */
  const fetchChannelMessages = (startTimeToken) => {
    if (!startTimeToken) setMessages([]);

    (async () => {
      try {
        const cm = await fetchMessages(pubnub, channelID, startTimeToken);

        const channelMessages = cm.channels[channelID];
        const messagesList = [];
        let messageObject = {};
        let messagesSet = [];

        setMore(cm.more || { start: 'end' });


        // console.log('data', channelMessages)

        if (channelMessages) {
          channelMessages.map((channelMessage, j) => {

            messageObject = channelMessage.message;
            messageObject.time = formatTimeToken(channelMessage.timetoken);
            messageObject.timetoken = channelMessage.timetoken;
            messageObject.actions = channelMessage.actions;
            messageObject.actionToken =
              channelMessage.actions &&
              channelMessage.actions.deleted &&
              channelMessage.actions.deleted["."][0].actionTimetoken;
            messageObject.text = getMessageText(channelMessage, messageObject);

            messageObject.message.text = getMessageText(channelMessage, messageObject);

            // Get the original senders uuid from the user's publish or
            // if message was sent to banned channel by a function, find it in the message payload
            let senderUuid = channelMessage.uuid || channelMessage.message.senderUuid;
            // Use the sender's uuid to add the name/avatar info to the message
            let filterMember =
              senderUuid && members && members.filter((member) => member.uuid.id === senderUuid);
            if (filterMember && filterMember.length) {
              messageObject.name = capitalizeFirstLetter(
                filterMember[0].uuid.name || filterMember[0].uuid.id
              );
              messageObject.profileUrl = filterMember[0].uuid.profileUrl;
            } else if (senderUuid) {
              // Display uuid even if there is no name metadata
              messageObject.name = senderUuid;
            }

            messageObject.name = channelMessage.message.message?.meta.name;
            // console.log('-->', channelMessage);

            formatBannedMessage(messageObject);
            messagesSet = getMessageFileUrl(messageObject, channelMessage, pubnub, channelID);
            messagesList.push(messagesSet);
            return false;
          });
        }

        //  console.log('Messages.js::setmessages', messagesList);

        if (startTimeToken) {
          // console.log('set before');
          setMessages([...new Set(messagesList), ...messages]);
        } else {
          // console.log('init new messages');
          setMessages([...new Set(messagesList)]);
        }
        setLoading(false)
        // console.log('-------->',more);
        setLoadMore(cm.start === 'end')
      } catch (e) {
        setLoading(false)
        setLoadMore(false)
      }
    })();
  };

  /**
   * Handles message updates action (edit, delte, undo delete)
   */
  const messageUpdate = (messageToken, actionToken, action, updatedResponse) => {

    let filteredArray = messages.filter((item) => item.timetoken !== messageToken);
    let filteredMessage = messages.filter((row) => {
      return row.timetoken.includes(messageToken);
    });
    if (action) {
      if (action === "undo") filteredMessage[0].actions = "";
      if (action === "updated" && updatedResponse) {
        //  console.log(updatedResponse);
        setMessageToEdit([]);
        filteredMessage[0].message.text = updatedResponse.value;
      } else if (action === "updated") {
        setMessageToEdit([]);
      }
    } else {
      //  console.log(updatedResponse);
      filteredMessage[0].actions = "deleted";
      filteredMessage[0].actionToken = actionToken;

    }
    const messageIndex = messages.findIndex((row) => {
      return row.timetoken.includes(messageToken);
    });
    filteredArray.splice(messageIndex, 0, filteredMessage[0]);
    setMessages(filteredArray);
  };

  const handleMessage = (message) => {
    let messageObject = {};
    // messages sent to banned channel by moderation include a senderUuid field
    let senderUuid = message.publisher || (message.message && message.message.senderUuid);
    let filterMember =
      senderUuid && members && members.filter((member) => member.uuid.id === senderUuid);
    messageObject = message.message || message;
    if (filterMember && filterMember.length) {
      messageObject.name = filterMember[0].uuid.name || filterMember[0].uuid.id;
      messageObject.profileUrl = filterMember[0].uuid.profileUrl;
    } else if (senderUuid) {
      // Display uuid even if there is no name metadata
      messageObject.name = senderUuid;
    }

    messageObject.name = message.message.message?.meta.name;

    if (
      message &&
      message.file &&
      message.file.id &&
      message.file.name &&
      messageObject.message.file.url
    ) {
      const url = pubnub.getFileUrl({
        channel: channelID,
        id: message.file.id,
        name: message.file.name,
      });
      messageObject.file.url = url;
    }
    messageObject.time = formatTimeToken(message.timetoken);
    messageObject.timetoken = message.timetoken;
    formatBannedMessage(messageObject);
    setMessages((oldArray) => [...oldArray, messageObject]);
  };

  const pubnubListener = {
    message: handleMessage,
    file: handleMessage,
  };

  const leaveChannel = () => {
    pubnub.removeListener(pubnubListener);
    pubnub.unsubscribeAll();
  };

  useEffect(() => {
    if (channelID) {
      pubnub.addListener(pubnubListener);
      pubnub.subscribe({ channels: [channelID] });
      return leaveChannel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, channelID]);

  const handleMemberDetails = (memberData) => {
    setMemberDetails(memberData);
    setToggleMemberDetails(true);
  };


  return (
    <>
      {/* <Grid justifyContent="space-between" container spacing={1} height="100%"> */}

      <Grid item sm={12} style={matches
        ? { backgroundColor: "#E7E7E7", height: "72.5vh", width: "90vw", margin: "0 auto", borderRadius: "10px" }
        : { backgroundColor: "#E7E7E7", height: "100vh", width: "100vw", borderRadius: "10px" }}>
        <Chat
          setScrollPosition={setScrollPosition}
          messages={messages}
          endLoad={endLoad}
          isLoading={loading}
          pubnub={pubnub}
          channel={location.state.channel}
          updated={messageUpdate}
          messageToEdit={messageToEdit}
          setMessageToEdit={setMessageToEdit}
          toggledVal={toggledVal}
        />
        {/* </Grid> */}

      </Grid >
    </>
  );
};

export default Messages;