import React from "react";
import Messages from "../../components/messages/Messages";
// import DashboardLayout from "../../layouts/Dashboard";
import { Box, Container, CssBaseline, Typography } from "@material-ui/core";
import Grid from '@mui/material/Grid'; // Grid version 1
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStyles } from "../../style/messages";
import { channelsFromLS, formatDate, showError } from "../../utils/helpers";
import useMediaQuery from '@mui/material/useMediaQuery';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  shadow: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const Overview = (props) => {

  const classes = useStyles();

  const matches = useMediaQuery('(min-width:400px)');

  console.log('classes', classes)

  console.log('overview::', channelsFromLS())
  console.log('Overview', props)
  return (
    <>
      {/* <DashboardLayout> */}
      {/* <CssBaseline /> */}
      <Container
        maxWidth={false}
        style={{
          backgroundColor: matches ? 'black' : '#E7E7E7',
          width: '100vw',
          display: 'flex',
          overflow: 'hidden',
          paddingLeft: 0,
          paddingRight: 0,
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {matches &&
          <Grid xs={12} style={{ height: '100vh', width: '90vw' }}>

            <Paper style={{ display: 'flex', justifyContent: 'space-between', height: '10vh', backgroundColor: 'black', marginBottom: '2.5vh' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <img src={process.env.PUBLIC_URL + "/images/logocomplete.svg"} style={{ height: '60%', marginTop: '10%' }} alt="" />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" style={{ color: 'white', fontFamily: "pantonbold", fontSize: "4vw" }}>Plenaire chat</Typography></Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <img src={process.env.PUBLIC_URL + "/images/logo.svg"} style={{ height: '90%' }} alt="" />
              </Box>
            </Paper>
          </Grid>
        }

        <Grid xs={12} style={matches ? { height: '100vh', width: '100vw' } : { height: '60vh', width: '100vw' }}>
          {/* <Paper style={{ height: '100%', overflow: 'hidden', backgroundColor: "#E7E7E7" }}> */}
          <Messages />
          {/* </Paper> */}
        </Grid>

        {matches &&
          <Grid xs={12} style={{ height: '10vh', width: '90vw', backgroundColor: 'black', marginTop: "2.5vh" }}>
            <Paper style={{ height: '10vh', backgroundColor: 'black', textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

              <Box>
                <Typography style={{ color: 'white', fontSize: '2vw', fontFamily: "pantonbold" }}>
                  <b>Heb je een vraag of opmerking?</b>
                </Typography><br />
                <Typography style={{ color: 'white', fontSize: '1vw', fontFamily: "panton" }}>
                  Download onze app via de QR-code en chat mee.
                </Typography>
              </Box>
              <img src={process.env.PUBLIC_URL + "/images/plenair.png"} style={{ height: '80%', marginLeft: '100px', border: '5px solid white', borderRadius: '5px' }} alt="" />
            </Paper>
          </Grid>
        }
      </Container>
      {/* </DashboardLayout> */}
    </>
  );
};

export default Overview;

