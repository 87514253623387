/**
 * Displays Messages in a selected channel
 */
import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, Grid, Typography, CircularProgress } from "@material-ui/core";
import { useStyles } from "../../style/messages";
import Alert from "@material-ui/lab/Alert";
import { formatUserName, formatProfileImageUrl } from "../../utils/helpers";

import MessageContent from "./MessageContent";

export default function Chat(props) {
  const classes = useStyles();

  const [selectedMessage, setSelectedMessage] = useState([]);
  const [messages, setMessages] = useState([]);
  const [undoConfirmOpen, setUndoConfirmOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [listenerAdded, setListenerAdded] = useState(false);
  const messagesEndRef = useRef(null);


  // used for throttle scroll
  const _ = require('lodash');
  const listRef = useRef(null);





  /**
   * Handles edit icon click to edit a message
   */
  const updateMessage = (event, message) => {
    //  console.log('chat.js::updateMessage',message);
    props.setMessageToEdit(message);
  };




  useEffect(() => {
    setMessages(props.messages);
    // const diffBottom = Math.abs(listRef.current?.offsetHeight + listRef.current?.scrollTop - listRef.current?.scrollHeight);
    // if (hasScrolled && props.endLoad && diffBottom < 100) {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView();
    }, 100);
    // }

    console.log('scroll?', messagesEndRef.current);

    setHasScrolled(true);
  }, [props.messages]);


  const scrollExec = (e) => {
    props.setScrollPosition(e.target.scrollTop === 0 ? 0 : 1);
  }


  useEffect(() => {
    setTimeout(() => {
      if (listRef && listRef.current) {
        if (listenerAdded) return;
        setListenerAdded(true);

        console.log('listener added');
        listRef.current.addEventListener('scroll', _.throttle(scrollExec, 200));

        return () => {
          listRef.current?.removeEventListener('scroll', _.throttle(scrollExec, 200));
        }
      }
    }, 2500);

  }, [listenerAdded])


  return (
    <Grid style={{ height: '100%' }} ref={listRef}>
      {messages.length || props.isLoading ? (
        <Grid item style={{ height: '100%', borderRadius: "10px" }} className={classes.messages} >
          {messages.map((message, n) => {
            // { console.log(message) }
            if (message?.actions?.deleted) return <></>

            return (
              <div key={n}>
                {props.toggledVal === "chat" && (
                  <>
                    <Grid container>
                      {/* <Box> */}
                      {/* <Avatar variant="square" src={formatProfileImageUrl(message.profileUrl)} /> */}
                      {/* </Box> */}
                      {/* <Typography className={classes.user}>
                        {formatUserName(message.name)}
                      </Typography> */}
                    </Grid>
                    <MessageContent
                      id="messageContent"
                      message={message}
                      updateMessage={updateMessage}
                      index={n}
                      toggledVal={props.toggledVal}
                    />
                    {/* <Typography className={classes.timeField}>{message.time}</Typography> */}
                  </>
                )}



                {/* {props.toggledVal === "banned" && (
                  <>
                    <Grid container>
                      <Box>
                        <Avatar variant="square" src={formatProfileImageUrl(message.profileUrl)} />
                      </Box>
                      <Typography className={classes.user}>
                        {formatUserName(message.name)} name
                      </Typography>
                    </Grid>
                    <MessageContent message={message} toggledVal={props.toggledVal} index={n} />
                    <Typography className={classes.timeField}>{message.time}</Typography>
                  </>
                )} */}
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </Grid>
      ) : (
        <Alert severity={"info"} className={classes.alertMessage}>
          No recent messages found
        </Alert>
      )
      }


      {
        props.isLoading ? (
          <Grid justifyContent="center" container>
            <Box pt={3}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : null
      }

      {/*       
      <EditMessage
        message={props.messageToEdit}
        channel={props.channel}
        pubnub={props.pubnub}
        updated={props.updated}
        messagesLength={messages.length}
      /> */}
    </Grid >
  );
}