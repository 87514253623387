/**
 * Displays Message content of a message in a selected channel
 */
import React from "react";
import { useStyles } from "../../style/messages";
import { Grid, Typography, Avatar } from "@material-ui/core";
import { formatUserName, formatProfileImageUrl } from "../../utils/helpers";
import useMediaQuery from '@mui/material/useMediaQuery';

const MessageContent = (props) => {
  const { message, index, toggledVal } = props;
  const classes = useStyles();

  console.log(message);
  const isModerator = message?.name?.trim().toLowerCase() === 'moderator'
  const matches = useMediaQuery('(min-width:400px)');

  return (
    <>
      {toggledVal === "chat" && (
        <Grid container className={classes.messageContainer} height={{ height: '100%', width: '90vw', borderRadius: '10px' }}
          style={{ justifyContent: isModerator ? 'flex-start' : 'flex-end' }}
        >

          <>
            <span
              className={isModerator ? (matches ? classes.chatModerator : classes.chatModeratorBig) : (matches ? classes.chat : classes.chatBig)}
              style={{
                backgroundColor: isModerator ? "#0058CA" : "white",
                // marginLeft: matches ? '5vw' : 0,
                // marginRight: matches ? '5vw' : 0,
                margin: matches ? '1vh 5vw' : 0,
              }}
            >
              <Typography className={matches ? classes.userBig : classes.user} style={{
                fontFamily: "panton",
                color: isModerator ? 'white' : 'black'
              }}
              >
                {formatUserName(message.name)}
                &nbsp;{new Date(message.timetoken * 0.0001).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </Typography>

              {message.message.text && (
                <Typography className={matches ? classes.chatTextBig : classes.chatText} style={{ color: isModerator ? 'white' : 'black' }}>{message.message.text}</Typography>
              )}

            </span>
          </>

        </Grid>
      )}

    </>
  );
};

export default MessageContent;