import React, { useEffect, useState } from "react";
import { applicationsWithKeyFromLS, selectedAppFromLS } from "../../utils/helpers";
import { CssBaseline } from "@material-ui/core";
import { setLocalStorage } from "../../services/localStorage";
// import { getChannels, getChannelsOccupancy } from "../../services/pubnub";
import usePubNub from "../../utils/usePubNub";
import { useHistory } from "react-router-dom";
import Messages from "../../components/messages/Messages";
import { useLocation } from "react-router";


const PUBLISH_KEY = "pub-c-1304b5ba-eb2d-47eb-b0d4-3908dfb8c5a5"

//529 test


const Chat = (props) => {
	const channelId = props?.match?.params?.id
	const app = applicationsWithKeyFromLS();


	const appInfo = (app) ? applicationsWithKeyFromLS()?.filter(row => row.publish_key === PUBLISH_KEY)[0] : false;
	const history = useHistory();


	useEffect(() => {
		setLocalStorage("PubNubSelectedApp", appInfo)
		console.log('set app & channel', channelId, appInfo)

		if (appInfo) {
			history.push({
				pathname: "/channels/messages",
				state: { channel: channelId },
			});
		} else {
			history.push({
				pathname: "/",
				state: { channel: channelId },
			});
		}
	}, []);


	return (
		<>
			<CssBaseline />
			<div>Loading channel {channelId}</div>
		</>
	);
}

export default Chat;